<template>
  <TepmplateBlock mod-class content-class="overflow-auto main-wrap pt-4">
    <img
      v-if="theme === 'light-theme'"
      src="../../src/resource/img/sun-toggle.svg"
      alt="sun-icon"
      @click="setThemeToggle()"
      style="position: absolute; right: 50%; top: 3%; filter: var(--filter-for-svg)"
    />
    <img
      v-else
      src="../../src/resource/img/moon-toggle.svg"
      alt="moon-icon"
      @click="setThemeToggle()"
      style="position: absolute; right: 50%; top: 3%; filter: var(--filter-for-svg)"
    />
    <b-alert
      v-if="
        !isHelloAlertDismissed &&
          ordersListV2 &&
          !ordersListV2.length &&
          isIntroPassed
      "
      show
      dismissible
      variant="info"
      @dismissed="rememberAlertDismiss"
    >
      Добрый день! Вы зарегистрировались в системе, которая позволяет упростить
      процесс обслуживания орг.техники. Добавляйте задачи и отслеживайте их
      выполнение. Добро пожаловать!
    </b-alert>
    <b-alert
      v-if="!isIntroPassed && !this.isProfileFilled"
      show
      variant="info"
      class="over-top"
    >
      Вы зарегистрировались в системе. Сейчас был запущен автоматический мастер,
      который поможет Вам заполнить основные данные профиля в пошаговом режиме.
    </b-alert>
    <ul v-if="!checkDisabled" class="p-0 main-list__list">
      <li
        class="main-list__item"
        v-for="item in items"
        :key="item.message"
        :class="[
          item.link ? '' : 'disabled',
          {
            colored:
              item.link &&
              needHighlightProfile &&
              item.link.indexOf('profile') > -1,
            'over-top':
              !isIntroPassed &&
              item.link &&
              ((item.link.indexOf('profile') > -1 && !isProfileFilled) ||
                (item.link.indexOf('request-list') > -1 && isProfileFilled))
          }
        ]"
      >
        <router-link
          v-if="!item.target"
          class="main-list__link d-flex align-items-center"
          :class="item.link ? '' : 'develop-link'"
          :to="isHasLink(item.link)"
          :title="!item.link ? 'Раздел находится в разработке' : ''"
        >
          <div
            v-if="item.link !== '/manager/chat'"
            class="main-list__icon-wrap"
          >
            <img class="main-list__icon" :src="item.src" alt="" />
          </div>
          <img v-else class="mr-2" :src="item.src" alt="" />
          <span
            class="pl-3"
            :class="{
              'font-weight-bold':
                (item.link &&
                  item.link.indexOf('request') > -1 &&
                  allOrdersChatNewMessagesCount > 0) ||
                (item.link &&
                  item.link.indexOf('chat') > -1 &&
                  allChatsNewMessagesCount > 0)
            }"
          >
            {{ item.message }}
          </span>
          <!-- <span
            class="font-weight-bold"
            v-if="
              item.link &&
                item.link.indexOf('request') > -1 &&
                allOrdersChatNewMessagesCount > 0
            "
          >
            {{ allOrdersChatNewMessagesCount }}
          </span>
          <span
            v-if="
              item.link &&
                item.link.indexOf('chats') > -1 &&
                allChatsNewMessagesCount > 0
            "
            class="font-weight-bold"
            >{{ allChatsNewMessagesCount }}</span
            > -->
          <b-badge
            v-if="
              item.link &&
                item.link.indexOf('request') > -1 &&
                allOrdersChatNewMessagesCount > 0
            "
            variant="info"
            class="mb-4"
          >
            {{ allOrdersChatNewMessagesCount }}
          </b-badge>
          <b-badge
            v-if="
              item.link &&
                item.link.indexOf('chats') > -1 &&
                allChatsNewMessagesCount > 0
            "
            variant="info"
            class="mb-4"
          >
            {{ allChatsNewMessagesCount }}
          </b-badge>
          <help-popover
            v-if="item.help"
            :message="item.help"
            class="ml-2 help-icon"
            :is-inline-block="false"
          />
        </router-link>

        <a
          v-else
          class="main-list__link"
          :class="item.link ? '' : 'develop-link'"
          :href="isHasLink(item.link)"
          :target="item.target"
          :title="!item.link ? 'Раздел находится в разработке' : ''"
        >
          <div
            v-if="item.link !== '/manager/chat'"
            class="main-list__icon-wrap"
          >
            <img class="main-list__icon" :src="item.src" alt="" />
          </div>
          <img v-else class="mr-2" :src="item.src" alt="" />
          <span class="pl-3">{{ item.message }}</span>
          <help-popover
            v-if="item.help"
            :message="item.help"
            class="ml-2 help-icon"
            :is-inline-block="false"
          />
        </a>
      </li>
    </ul>

    <div v-else class="w-100">
      <ul class="p-0 main-list__list">
        <li
          class="main-list__item"
          v-for="item in items"
          :key="item.message"
          :class="[
            item.link ? '' : 'disabled',
            {
              colored:
                item.link &&
                needHighlightProfile &&
                item.link.indexOf('profile') > -1,
              'over-top':
                !isIntroPassed &&
                item.link &&
                ((item.link.indexOf('profile') > -1 && !isProfileFilled) ||
                  (item.link.indexOf('request-list') > -1 && isProfileFilled))
            }
          ]"
        >
          <router-link
            v-if="!item.target"
            class="main-list__link"
            :class="item.link ? '' : 'develop-link'"
            :to="isHasLink(item.link)"
            :title="!item.link ? 'Раздел находится в разработке' : ''"
          >
            <div
              v-if="item.link !== '/manager/chat'"
              class="main-list__icon-wrap"
            >
              <img class="main-list__icon" :src="item.src" alt="" />
            </div>
            <img v-else class="mr-2" :src="item.src" alt="" />
            <span class="pl-3">{{ item.message }}</span>
            <help-popover
              v-if="item.help"
              :message="item.help"
              class="ml-2 help-icon"
              :is-inline-block="false"
            />
          </router-link>

          <a
            v-else
            class="main-list__link"
            :class="item.link ? '' : 'develop-link'"
            :href="isHasLink(item.link)"
            :target="item.target"
            :title="!item.link ? 'Раздел находится в разработке' : ''"
          >
            <div
              v-if="item.link !== '/manager/chat'"
              class="main-list__icon-wrap"
            >
              <img class="main-list__icon" :src="item.src" alt="" />
            </div>
            <img v-else class="mr-2" :src="item.src" alt="" />
            <span class="pl-3">{{ item.message }}</span>
            <help-popover
              v-if="item.help"
              :message="item.help"
              class="ml-2 help-icon"
              :is-inline-block="false"
            />
          </a>
        </li>
      </ul>
    </div>
    <div
      v-if="!isIntroPassed && ordersListV2 && !ordersListV2.length"
      class="dark-intro-background full"
    >
      <div class="intro-tip-wrapper">
        <!-- <div class="intro-target"></div> -->
        <div v-if="!isProfileFilled" class="intro-tip p-2 w-25 text-center">
          Перейдите в раздел "Профиль"
        </div>
        <div v-else class="intro-tip p-2 w-25 text-center">
          Теперь перейдем в раздел "Задачи"
        </div>
      </div>
    </div>
    <div
      v-if="!isIntroPassed && ordersListV2 && ordersListV2.length"
      class="dark-intro-background"
    >
      <div class="intro-tip-wrapper">
        <!-- <div class="intro-target"></div> -->
        <div class="intro-tip p-2 w-25 text-center">
          Отлично! Вы создали первую задачу. Скоро с вами свяжется ваш
          персональный менеджер.<br />
          <div class="d-flex align-items-center justify-content-center">
            <b-button
              type="button"
              class="btn-primary mt-4"
              @click="doPassIntro"
              >Спасибо</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "../components/TepmplateBlock";
import HelpPopover from "../components/HelpPopover";
import { mapGetters, mapActions } from "vuex";
import { theme, toggleTheme } from "../main";
import RequestManager from "@/function/request/RequestManager";
import { doPoseTip } from "@/utils";
import eventBus from "@/utils/event-bus";

export default {
  name: "Main",
  components: { TepmplateBlock, HelpPopover },
  computed: {
    ...mapGetters(["currentUserV2", "bankInfo", "officeList"]),
    checkDisabled() {
      let result = false;
      for (let key in this.bankInfo) {
        if (this.bankInfo[key] === null) {
          result = true;
        }
      }
      if (this.officeList !== undefined && this.officeList.length === 0) {
        return true;
      }
      result = false;
      return result;
    }
  },
  async created() {
    const currentUserSrc = localStorage.getItem("currentUser");
    if (currentUserSrc) {
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

      const ordersListResponse = await RequestManager().ordersApi.listOrders({
        companyId: (
          this.currentUser.company || this.currentUser.companyId
        ).toString(),
        limit: 1
      });
      this.ordersListV2 = ordersListResponse.items;
      this.allOrdersChatNewMessagesCount =
        ordersListResponse.countWithNewMessages;
      const chatListResponse = await RequestManager().chatApi.listChats({
        type: ["order", "contact", "group"],
        limit: 0
      });
      this.allChatsNewMessagesCount = chatListResponse.countWithNewMessages;
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      this.GET_BANK_INFO();
      this.items = this.items.filter(item => {
        if (item.message === "Профиль") {
          item.link =
            this.currentUser.role == 3 ? "/my-profile" : "manager-profile";
        }
        if (item.message === "Управление клиентами") {
          if (this.currentUser.role === "3" || this.currentUser.role === "4") {
            return;
          }
        }
        return item;
      });
      eventBus.$on("token-v2-set", this.getV2DataIfNeeded);
      eventBus.$on("chat_new-message", message => {
        this.allChatsNewMessagesCount += 1;
        console.log(message);
      });
      eventBus.$on("order_new-message", this.incrementAllOrdersMessagesCount);
    }
  },
  async mounted() {
    console.log("main mounted");
    // Получение данных о заполненности профиля
    this.adminProfile = await RequestManager().getAdminProfile();
    this.SET_ADMIN_PROFILE(this.adminProfile);
    this.mainCompanyInfo = await RequestManager().getMainCompanyInfo();
    window.localStorage.setItem(
      "mainCompanyInfo",
      JSON.stringify(this.mainCompanyInfo)
    );

    this.needHighlightProfile =
      !this.mainCompanyInfo.ogrn ||
      !this.adminProfile.website ||
      !this.bankInfo?.bank_bik;

    this.isAdminProfileFilled = !this.needHighlightProfile;
    if (this.needHighlightProfile) {
      localStorage.setItem("isAdminProfileFilled", "false");
    } else {
      localStorage.setItem("isAdminProfileFilled", "true");
    }

    this.isHelloAlertDismissed = localStorage.getItem(
      `isHelloAlertDismissed_${this.currentUser.id}`
    );
    if (localStorage.getItem("accessTokenV2") !== "") {
      this.getV2Data();
    }
  },
  methods: {
    incrementAllOrdersMessagesCount() {
      this.allOrdersChatNewMessagesCount += 1;
    },
    doPassIntro() {
      this.isIntroPassed = true;
      localStorage.setItem("isIntroPassed", "true");
    },
    async getV2Data() {
      this.mainCompanyInfoV2 = await RequestManager().companyApi.getCompany(
        this.currentUser.company || parseInt(this.currentUser.companyId)
      );
      localStorage.setItem(
        "mainCompanyInfoV2",
        JSON.stringify(this.mainCompanyInfoV2)
      );
      /* const ordersListResponse = await RequestManager().ordersApi.listOrders({
        companyId: (
          this.currentUser.company || this.currentUser.companyId
        ).toString(),
        limit: 1
      });
      this.ordersListV2 = ordersListResponse.items;
      this.allOrdersChatNewMessagesCount =
        ordersListResponse.countWithNewMessages;
      const chatListResponse = await RequestManager().chatApi.listChats({
        type: ["order", "contact", "group"],
        limit: 0
      });
      this.allChatsNewMessagesCount = chatListResponse.countWithNewMessages; */
      this.startIntroIfNeeded();
      if (!this.ordersListV2 || this.ordersListV2.length === 0) {
        setTimeout(() => {
          doPoseTip(".over-top", "bottom");
        }, 100);
      }
    },
    getV2DataIfNeeded() {
      if (!this.ordersList) {
        this.getV2Data();
      }
    },
    ...mapActions(["SET_ADMIN_PROFILE", "GET_BANK_INFO"]),
    isHasLink(link) {
      return link ? link : "/main";
    },
    setThemeToggle() {
      if (localStorage.theme === "light-theme") {
        localStorage.setItem("theme", "dark-theme");
      } else {
        localStorage.setItem("theme", "light-theme");
      }
      location.reload();
      toggleTheme();
    },
    rememberAlertDismiss() {
      localStorage.setItem(
        `isHelloAlertDismissed_${this.currentUser.id}`,
        "true"
      );
    },
    startIntroIfNeeded() {
      if (!this.mainCompanyInfoV2?.phone) {
        this.isProfileFilled = false;
      } else if (
        !this.mainCompanyInfoV2?.legalEntityAddress?.length ||
        !this.mainCompanyInfoV2?.legalEntity?.length
      ) {
        this.isProfileFilled = false;
      } else if (!this.ordersListV2?.length) {
        this.isProfileFilled = true;
      }
      console.log("this.officeList.length=", this.officeList.length);
      if (this.officeList.length < 1) {
        this.isProfileFilled = false;
      } else {
        localStorage.setItem("isOfficeAdded", "true");
        this.isOfficeAdded = true;
      }
      if (this.ordersListV2.length) {
        this.isIntroPassed = true;
        localStorage.setItem("isIntroPassed", "true");
      }

      if (
        (this.officeList.length < 1 ||
          !this.mainCompanyInfoV2.phone ||
          !this.mainCompanyInfoV2.legalEntityAddress.length ||
          !this.mainCompanyInfoV2.legalEntity.length ||
          !this.ordersListV2.length) &&
        this.currentUser.role == 3
      ) {
        this.isIntroPassed = false;
        localStorage.setItem("isIntroPassed", "false");
      } else {
        this.isIntroPassed = true;
        localStorage.setItem("isIntroPassed", "true");
      }

      //this.$router.replace({ name: "AdminProfileIntroRegistration" });
    }
  },
  data() {
    return {
      theme: theme,
      currentUser: undefined,
      currentUserV2Data: {},
      isIntroPassed: true,
      isProfileFilled: undefined,
      mainCompanyInfoV2: undefined,
      ordersListV2: undefined,
      items: [
        // {
        //   message: "Задачи",
        //   link: "/my-request",
        //   availableRoles: [],
        //   src: require("../resource/img/home-icon/paper.svg"),
        //   help: "Список всех заявок, фильтр, поиск, создание заявок"
        // },
        {
          message: "Задачи",
          availableRoles: [],
          src: require("../resource/img/home-icon/paper.svg"),
          link: "/request-list",
          help: "Список всех заявок, фильтр, поиск, создание заявок"
        },
        {
          message: "Коллеги, Контакты",
          link: "/colleagues-contacts",
          availableRoles: [],
          src: require("../resource/img/home-icon/contacts.svg"),
          help: "Договор с условиями работы"
        },
        {
          message: "Техника",
          link: "/my-techique",
          availableRoles: [],
          src: require("../resource/img/home-icon/printer.svg"),
          help:
            "Список всей техники, поиск, фильтр, создание заявок с привязкой к конкретному аппарату"
        },
        {
          message: "Чаты",
          link: "/chats",
          availableRoles: [],
          src: require("../resource/img/home-icon/chats.svg"),
          help: "Создание чатов и групповых чатов"
        },
        {
          message: "Мониторинг. Аналитика",
          availableRoles: [],
          src: require("../resource/img/home-icon/announcement.svg"),
          help: "Список новостей"
        },
        {
          message: "Календарь",
          link: "/calendar",
          availableRoles: [],
          src: require("../resource/img/home-icon/calendar.svg"),
          help: "Календарь с просмотром задач"
        },
        // {
        //   message: "Договора",
        //   availableRoles: [],
        //   src: require("../resource/img/home-icon/receipt.svg"),
        //   help: "Договор с условиями работы"
        // },
        {
          message: "Профиль",
          link: "/my-profile",
          availableRoles: [],
          src: require("../resource/img/home-icon/account.svg"),
          help:
            "Для начала работы в системе вам необходимо заполнить свой профиль"
        },
        {
          message: "Инструкции",
          availableRoles: [],
          src: require("../resource/img/home-icon/information.svg"),
          link: "/instruction",
          help:
            "Ответы на вопросы, которые могут возникнуть при работе с приложением"
        },
        {
          message: "Управление клиентами",
          availableRoles: [],
          src: require("../resource/img/home-icon/account.svg"),
          link: "/superadmin",
          help: "$$$ Супер возможности $$$"
        }
        // {
        //   message: "Ваш персональный менеджер",
        //   link: "/manager/chat",
        //   src: require("../resource/img/personal-man.svg"),
        //   help: "Ваш личный менеджер"
        // }
      ],
      adminProfile: {},
      mainCompanyInfo: {},
      needHighlightProfile: false,
      isHelloAlertDismissed: true,
      allOrdersChatNewMessagesCount: 0,
      allChatsNewMessagesCount: 0
    };
  }
};
</script>

<style lang="scss" scoped>
.main-list {
  margin: 25px 0 0 0;
  padding: 0;
}

.main-list__list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-gap: 15px;
}

.main-list__item {
  width: 100%;
  height: 96px;
  list-style-type: none;
  background: var(--main-card-color);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
}
.main-list__item.colored {
  background-color: #d1ecf1;
}

.main-list__link {
  position: relative;
  align-items: center;
  padding: 17px 20px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: var(--card-text-color);
  display: grid;
  grid-template-columns: 62px 1fr;
}

.main-list__icon-wrap {
  position: relative;
  width: 62px;
  height: 62px;
  background: rgba(81, 162, 190, 0.15);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.main-list__icon {
  //position: absolute;
  //top: 20px;
  //left: 20px;
}

.help-icon {
  position: absolute;
  top: 7px;
  right: 13px;
}

.disabled {
  background-color: #dbdbdb87;
  opacity: 0.6;
}

.flex-1 {
  flex: 1;
}
</style>
